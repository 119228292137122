<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" v-if="studentFinanceRecords && transactionTypes">
    <div class="card-header border-0 cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Transactions</h3>
      </div>
      <!--end::Card title-->
    </div>

    <div class="card-body border-top p-9">
        <div align="right">
            <el-input
                v-model="search.searchValue"
                size="mini"
                placeholder="Type to search"
                class="col-md-4"/>
        </div>
      <el-table 
        :data="pagedTransactions" 
        style="width: 100%"
        :default-sort = "{prop: 'date', order: 'descending'}">
        <el-table-column prop="tranactionDate" label="Date">
            <template v-slot="scope">
                <el-date-picker
                    v-if="scope.row.id == 0"
                    v-model="scope.row.record_date"
                    type="date"
                    start-placeholder="transaction date"
                    class="w-100"
                    format="MM/DD/YYYY">
                </el-date-picker>
                <div style="display: inline-block;" v-else>
                    <label> {{moment(scope.row.record_date).format('MM/DD/YYYY')}} </label>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="record_type" label="Type">
            <template v-slot="scope">
                <el-select
                    v-if="scope.row.id == 0"
                    class="form-select-solid"
                    v-model="scope.row.record_type">
                    <el-option v-for="(type, index) in transactionTypes" :key="index" :label="type" :value="type">
                        {{type}}
                    </el-option>
                </el-select>
                <div style="display: inline-block;" v-else>
                    <label> {{scope.row.record_type}} </label>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="amount" label="Amount">
            <template v-slot="scope">
                <el-input
                    v-if="scope.row.id == 0"
                    v-model="scope.row.amount"
                    style="width:100%;"
                />
                <div style="display: inline-block;" v-else>
                    <label> {{scope.row.amount}} </label>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="payment_method" label="Method">
            <template v-slot="scope">
                <el-select
                    v-if="scope.row.id == 0"
                    class="form-select-solid"
                    v-model="scope.row.payment_method">
                    <el-option v-for="(type, index) in paymentMethods" :key="index" :label="type" :value="type">
                        {{type}}
                    </el-option>
                </el-select>
                <div style="display: inline-block;" v-else>
                    <label> {{scope.row.payment_method}} </label>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="payment_type" label="Category" >
            <template v-slot="scope">
                <el-select
                    v-if="scope.row.id == 0"
                    class="form-select-solid"
                    v-model="scope.row.payment_type">
                    <el-option v-for="(type, index) in filteredPaymentTypes" :key="index" :label="type" :value="type">
                        {{type}}
                    </el-option>
                </el-select>
                <div style="display: inline-block;" v-else>
                    <label> {{scope.row.payment_type}} </label>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="memo" label="Memo" width="280">
            <template v-slot="scope">
                <el-input
                    v-if="scope.row.id == 0"
                    v-model="scope.row.memo"
                    style="width:100%;"
                />
                <div style="display: inline-block;" v-else>
                    <label> {{scope.row.memo}} </label>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="right" width="50">
            <template #default="scope">
                <span v-if="scope.row.id == 0"
                    class="svg-icon svg-icon-2x svg-icon-primary cursor-pointer" title="Add" 
                    @click="handleAdd(scope.$index, scope.row)">
                    <inline-svg src="/media/icons/duotune/general/gen041.svg" />
                </span>
                <img v-else src="/media/icons/duotune/general/gen027.svg" class="cursor-pointer fs-2x delete_icon" title="Delete" 
                    @click="handleDelete(scope.$index, scope.row)"/>
            </template>
        </el-table-column>
      </el-table>
      <div align="right">
        <el-pagination layout="prev, pager, next" v-model:page-size="pageSize" :total="studentFinanceRecords.length" @current-change="setPage"></el-pagination>
      </div>
    </div>
  </div>
  <!--end::details View-->

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, onBeforeMount, ref } from "vue";
import { ElMessageBox } from "element-plus";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";

export default defineComponent({
  name: "student-ledger",
  components: {
  },
  setup() {
      const store = useStore();
      const route = useRoute();
    const studentId : number = +route.params.id; 
    const user = store.getters.currentUser;
    const transactionTypes = reactive([]);
    const paymentMethods = reactive([]);
    const paymentTypes = reactive([]);

    const newStudentRecord = {
        id : 0,
        record_type :'',
        record_date  :'',
        amount  : 0,
        payment_method  :'',
        payment_type  :'',
        memo  :'',
        student_id: studentId,
        user_id: user.id
    };

    const studentFinanceRecords : any[] = reactive([newStudentRecord]);

    const getTypes = async() =>{
        ApiService.setHeader();
        await ApiService.get("finance/types")
                .then(({ data }) => {
                    Object.assign(transactionTypes, data.recordTypes);
                    Object.assign(paymentMethods,data.paymentMethods);
                    Object.assign(paymentTypes,data.paymentTypes);
                })
                .catch(({ response }) => {
                    console.log('getTypes error => '+response.data.errors);
                });
    }

    const filteredPaymentTypes = computed(() =>{
        var currentPaymentTypes = [];
        var newRecord = studentFinanceRecords.filter(x=>x.id==0)[0];
        if(newRecord.record_type){
            currentPaymentTypes = paymentTypes[newRecord.record_type];
        }

        return currentPaymentTypes;
    })

    const getStudentFinanceRecords = async () => {
        await new Promise((resolve, reject) => {  
            ApiService.setHeader();          
            ApiService.get("finance/records/"+studentId)
                .then(({ data }) => {
                    var recordData = data.data;
                    recordData.unshift(newStudentRecord);
                    Object.assign(studentFinanceRecords,recordData);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    console.log('getStudentFinanceRecords error => '+response.data.errors);
                    reject(new Error ('getStudentFinanceRecords error => '+response.data.errors));
                });
        });
    }

    const pageSize = 20;
    let page = reactive({pageValue : 1});

    const pagedTransactions = computed(()=>{
      return studentFinanceRecords
                .filter(data => !search 
                            || data.record_type.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.payment_method.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.payment_type.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.amount.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.memo.toLowerCase().includes(search.searchValue.toLowerCase()))
                .sort((x, y) => x.id == 0 || y.id == 0 ? 1 : +new Date(y.record_date) - +new Date(x.record_date))
                .slice(pageSize * page.pageValue - pageSize, pageSize * page.pageValue)
    })

    const setPage = (val) => {
      page.pageValue = val;
    }

    const handleDelete = (index, row) => {
        ElMessageBox.confirm('Are you sure to delete this transaction?')
        .then(() => {
            ApiService.setHeader();
            ApiService.delete("finance/record/"+row.id)
                .then(() => {
                    let index = studentFinanceRecords.findIndex(d => d.id === row.id);
                    studentFinanceRecords.splice(index,1);   
                })
                .catch(({ response }) => {
                    console.log('handleDelete error => '+response.data.errors);
                });
        });
      
    };

    const handleAdd = (index, row) => {
        ApiService.setHeader();
        ApiService.post("finance/record", row)
            .then((data)=>{
                row.record_type ='';
                row.record_date ='';
                row.amount = 0;
                row.payment_method ='';
                row.payment_type ='';
                row.memo ='';

                studentFinanceRecords.push(data.data.record);                
            })
            .catch(({ response }) => {
                console.log('createFinanceRecords error => '+response.data.errors);
                Swal.fire({
                  text: "Failed to create the new transaction",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            });
    };

    const search = reactive({searchValue : ''});

    onBeforeMount(() => {
        getTypes();
        getStudentFinanceRecords();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Ledger", ["Student"]);
    });

    return {
        studentFinanceRecords,
        pagedTransactions,
        pageSize,
        page,
        search,
        setPage,
        handleDelete,
        handleAdd,
        transactionTypes,
        paymentMethods,
        paymentTypes,
        moment,
        filteredPaymentTypes
    };
  },
});
</script>

<style scoped>
* >>> .delete_icon {
    opacity: 0.3;
}

* >>> .delete_icon:hover {
    pointer-events: all;
    opacity: 0.7 !important;
}

* >>> .add_icon{
    opacity: 0.3;
    color:red;
}

* >>> .add_icon:hover {
    pointer-events: all;
    opacity: 0.7 !important;
}
</style>

