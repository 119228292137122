
import { defineComponent, onMounted, reactive, computed, onBeforeMount, ref } from "vue";
import { ElMessageBox } from "element-plus";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";

export default defineComponent({
  name: "student-ledger",
  components: {
  },
  setup() {
      const store = useStore();
      const route = useRoute();
    const studentId : number = +route.params.id; 
    const user = store.getters.currentUser;
    const transactionTypes = reactive([]);
    const paymentMethods = reactive([]);
    const paymentTypes = reactive([]);

    const newStudentRecord = {
        id : 0,
        record_type :'',
        record_date  :'',
        amount  : 0,
        payment_method  :'',
        payment_type  :'',
        memo  :'',
        student_id: studentId,
        user_id: user.id
    };

    const studentFinanceRecords : any[] = reactive([newStudentRecord]);

    const getTypes = async() =>{
        ApiService.setHeader();
        await ApiService.get("finance/types")
                .then(({ data }) => {
                    Object.assign(transactionTypes, data.recordTypes);
                    Object.assign(paymentMethods,data.paymentMethods);
                    Object.assign(paymentTypes,data.paymentTypes);
                })
                .catch(({ response }) => {
                    console.log('getTypes error => '+response.data.errors);
                });
    }

    const filteredPaymentTypes = computed(() =>{
        var currentPaymentTypes = [];
        var newRecord = studentFinanceRecords.filter(x=>x.id==0)[0];
        if(newRecord.record_type){
            currentPaymentTypes = paymentTypes[newRecord.record_type];
        }

        return currentPaymentTypes;
    })

    const getStudentFinanceRecords = async () => {
        await new Promise((resolve, reject) => {  
            ApiService.setHeader();          
            ApiService.get("finance/records/"+studentId)
                .then(({ data }) => {
                    var recordData = data.data;
                    recordData.unshift(newStudentRecord);
                    Object.assign(studentFinanceRecords,recordData);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    console.log('getStudentFinanceRecords error => '+response.data.errors);
                    reject(new Error ('getStudentFinanceRecords error => '+response.data.errors));
                });
        });
    }

    const pageSize = 20;
    let page = reactive({pageValue : 1});

    const pagedTransactions = computed(()=>{
      return studentFinanceRecords
                .filter(data => !search 
                            || data.record_type.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.payment_method.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.payment_type.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.amount.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.memo.toLowerCase().includes(search.searchValue.toLowerCase()))
                .sort((x, y) => x.id == 0 || y.id == 0 ? 1 : +new Date(y.record_date) - +new Date(x.record_date))
                .slice(pageSize * page.pageValue - pageSize, pageSize * page.pageValue)
    })

    const setPage = (val) => {
      page.pageValue = val;
    }

    const handleDelete = (index, row) => {
        ElMessageBox.confirm('Are you sure to delete this transaction?')
        .then(() => {
            ApiService.setHeader();
            ApiService.delete("finance/record/"+row.id)
                .then(() => {
                    let index = studentFinanceRecords.findIndex(d => d.id === row.id);
                    studentFinanceRecords.splice(index,1);   
                })
                .catch(({ response }) => {
                    console.log('handleDelete error => '+response.data.errors);
                });
        });
      
    };

    const handleAdd = (index, row) => {
        ApiService.setHeader();
        ApiService.post("finance/record", row)
            .then((data)=>{
                row.record_type ='';
                row.record_date ='';
                row.amount = 0;
                row.payment_method ='';
                row.payment_type ='';
                row.memo ='';

                studentFinanceRecords.push(data.data.record);                
            })
            .catch(({ response }) => {
                console.log('createFinanceRecords error => '+response.data.errors);
                Swal.fire({
                  text: "Failed to create the new transaction",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            });
    };

    const search = reactive({searchValue : ''});

    onBeforeMount(() => {
        getTypes();
        getStudentFinanceRecords();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Ledger", ["Student"]);
    });

    return {
        studentFinanceRecords,
        pagedTransactions,
        pageSize,
        page,
        search,
        setPage,
        handleDelete,
        handleAdd,
        transactionTypes,
        paymentMethods,
        paymentTypes,
        moment,
        filteredPaymentTypes
    };
  },
});
